import CookieConsent from "react-cookie-consent";

import {Link} from "react-router-dom";

export default function CookieBanner() {
  //some button styling in CSS component under default class "#rcc-confirm-button"

  return (
    <div className="text-center">
      <CookieConsent
        ariaAcceptLabel
        buttonText="I have read it and I agree"
        disableStyles={true}
        buttonStyle={{
          color: "#29282d",
          background: "#b65d6d",
          fontWeight: "600",
          borderRadius: "5px",
          borderColor: "#b65d6d",
        }}
        expires={300}
        style={{
          backgroundColor: "#34171c",
          display: "flex",
          flex: "1 0 5px",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "center",
          alignContent: "space-around",
          alignItems: "center",
          zIndex: "1",
          padding: "50px 20px",
          position: "fixed",
          bottom: "0",
          left: "0",
          right: "0",
          width: "100vw",
        }}
      >
        <p className="text-center">
          This site uses cookies and by using this site, you agree with its{" "}
          <Link className="footlink" title="Privacy Policy" to="/privacy">
            Privacy Policy
          </Link>
          .
        </p>
      </CookieConsent>
    </div>
  );
}
