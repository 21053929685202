import React from "react";

export default function WebImgPreviews(props) {
  let alt = "Image preview of ";

  return (
    <img
      src={props.source}
      className="img-fluid project-previews"
      alt={alt + props.alt}
    />
  );
}
