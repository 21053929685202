import React from "react";

//Common
import BackToTop from "../../common/BackToTop";
import IconMapping from "../../common/IconMapping";
import TryBtn from "../../common/TryBtn";
import WebImgPreviews from "../../common/WebImgPreviews";

//Img
import horoscope from "../../imgs/horoscope.png";

export default function HoroscopeProj() {
  return (
    <div className="px-2 mt-4" id="horoscope">
      <h4 className="text-center pt-2">React Horoscope App</h4>
      <div className="row pt-3">
        <div className="col-sm-6">
          <WebImgPreviews alt="the React Horoscope App" source={horoscope} />
        </div>
        <div className="col-sm-6">
          <p className="py-2 image-descriptors">
            View your own horoscope and others for today. You can also click on
            the buttons to change to the day of your choice. This projects
            collects results from <b>multiple API calls</b>,{" "}
            <b>adds data to the API results</b> and{" "}
            <b>redesigns the output reults</b>.
          </p>
          <p className="py-2 image-descriptors">
            This project is{" "}
            <b>
              optimised for <IconMapping icon="desktop" /> desktop,{" "}
              <IconMapping icon="tablet" /> tablet and{" "}
              <IconMapping icon="mobile" /> mobile devices
            </b>
            , and also <b>hides/shows elements based on your device</b>.
          </p>
          <p className="image-descriptors">
            The main technologies used include:
          </p>
          <ul className="tech-stack-proj image-descriptors">
            <li>
              <IconMapping icon="react" />
            </li>
            <li>
              <IconMapping icon="css" />
            </li>
            <li>
              <IconMapping icon="api" />
            </li>
            <li>
              <IconMapping icon="github" />
            </li>
            <li>
              <IconMapping icon="netlify" />
            </li>
          </ul>
          <span className="btns-outer-margin image-descriptors">
            <TryBtn
              outlink="https://beamish-zabaione-93b69b.netlify.app/"
              title="React Horosope App"
            />
          </span>
        </div>
      </div>
      <BackToTop />
    </div>
  );
}
