import React from "react";

import DictionaryProj from "./project-content/DictionaryProj";
import HoroscopeProj from "./project-content/HoroscopeProj";
import MyWebsite from "./project-content/MyWebsite";
import ReactWeatherProj from "./project-content/ReactWeatherProj";
import VanillaWeatherProj from "./project-content/VanillaWeatherProj";

export default function ProjectsTable() {
  return (
    <div>
      <MyWebsite />
      <HoroscopeProj />
      <DictionaryProj />
      <ReactWeatherProj />
      <VanillaWeatherProj />
    </div>
  );
}
