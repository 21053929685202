import React from "react";

import {Link} from "react-router-dom";

import TechTools from "../common/TechTools";
import IconMapping from "../common/IconMapping";

export default function Home() {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <div className="text-center px-2">
      <img
        src="https://media.licdn.com/dms/image/C5603AQFrX4DONZQldw/profile-displayphoto-shrink_200_200/0/1574984017291?e=1687996800&v=beta&t=1Ul2eXhEumohzNCkWnT_6TQUgYZAoPr09nMZvlNlI2U"
        className="img-self"
        alt="Sylvia"
      />
      <h1>About Me</h1>
      <p className="my-2 col-12 text-center">
        Hi, I'm Sylvia (
        <i>
          <b>she</b>/<b>her</b>
        </i>
        ) and welcome to my website, where I showcase some of my projects.
      </p>
      <p className="my-2 col-12 text-center">
        I'm a creative technologist and operations professional based in Berlin,
        and I'm currently open to new opportunities.
      </p>
      <p className="mt-4 mb-2 col-12 text-center">
        Currently I'm learing <IconMapping icon="typescript" /> and{" "}
        <IconMapping icon="language" /> B2 German, and in my free time I like to
        cook, visit museums and galleries, explore unknown corners of my city
        and travelling!
      </p>
      <TechTools />

      <div className="mt-4 my-3">
        <Link
          className="go-to-projs"
          title="Check out my projects"
          to="/projects"
          onClick={scrollToTop}
        >
          <IconMapping icon="arrowFwd" /> Go to my Projects
        </Link>
      </div>
    </div>
  );
}
