import React from "react";

export default function BackToTop(props) {
  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <div className="text-center px-2">
      <button className="back-to-top" onClick={scrollToTop}>
        Back to top
      </button>
    </div>
  );
}
