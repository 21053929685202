import React, {useState} from "react";
import Collapse from "react-bootstrap/Collapse";

import IconMapping from "./IconMapping";

export default function TechTools() {
  const [open, setOpen] = useState(true);
  return (
    <div className="mt-5 text-center">
      <h3 className="text-center">Tools & Stack</h3>
      <p>
        My full tech stack, tools and skills used include{" "}
        <span className="stack-collapse">
          <button
            onClick={() => setOpen(!open)}
            aria-controls="expand-tech-stack"
            aria-expanded={open}
            title="Open/collapse the full stack"
          >
            <i className="fa-solid fa-caret-down"></i>
          </button>
        </span>
      </p>

      <Collapse in={open}>
        <div id="expand-tech-stack">
          <div className="row my-3 text-center">
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="api" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="bootstrap" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="css" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="figma" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="flexbox" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="git" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="github" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="html" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="js" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="netlify" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="nodeJS" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="react" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="seo" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="typescript" />
              </p>
            </div>
            <div className="col-4 text-center">
              <p className="tech-stack">
                <IconMapping icon="vsCode" />
              </p>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
