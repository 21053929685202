import React from "react";

import {Link} from "react-router-dom";
import {NavLink} from "react-router-dom";

import IconMapping from "./IconMapping";

export default function CustomNavbar() {
  function handleContactMe() {
    document.getElementById("contact").scrollIntoView({behavior: "smooth"});
  }

  let activeClassName = "nav-active-page";

  return (
    <div className="nav-bar">
      <ul className="nav-list">
        <li>
          <IconMapping icon="menu" />
        </li>
        <li>
          <NavLink
            to="/"
            className={({isActive}) => (isActive ? activeClassName : undefined)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/projects"
            className={({isActive}) => (isActive ? activeClassName : undefined)}
          >
            Projects
          </NavLink>
        </li>
        <li>
          <Link to="#contact" onClick={handleContactMe}>
            Contact Me
          </Link>
        </li>
      </ul>
    </div>
  );
}
