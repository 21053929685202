import React from "react";

import ProjectsTable from "./ProjectsTable";
import TechTools from "../common/TechTools";

export default function ProjectsMain() {
  return (
    <div className="text-center px-2" id="main">
      <h1 className="col-12">Projects</h1>

      <p>
        Here you can see my portfolio on what I have been working on.
        <br />
        Included in the brief overview of the project is: a page preview,
        description of how it works, the main technologies used and device(s) it
        is optimised for.
      </p>

      <p className="m-2">
        Some projects are open-sourced, but I am happy to open-up the ones that
        are not with potential colleagues.{" "}
      </p>

      <TechTools />

      <h3 className="col-12 mt-5" id="#top">
        List of Apps:
      </h3>

      <ul className="col-12 app-list-menu">
        <li className="">
          <a target="_self" href="#here">
            This Website
          </a>{" "}
          |
        </li>
        <li className="">
          <a target="_self" href="#horoscope">
            React Horoscope
          </a>{" "}
          |
        </li>
        <li className="">
          <a target="_self" href="#dictionary">
            React Dictionary
          </a>{" "}
          |
        </li>
        <li className="">
          <a target="_self" href="#weather">
            React Weather
          </a>{" "}
          |
        </li>

        <li className="">
          <a target="_self" href="#vanilla">
            Vanilla JS Weather
          </a>{" "}
          |
        </li>
      </ul>
      <ul className="col-12 app-list-menu"></ul>

      <ProjectsTable />
    </div>
  );
}
