import React from "react";

//Common
import BackToTop from "../../common/BackToTop";
import IconMapping from "../../common/IconMapping";
import TryBtn from "../../common/TryBtn";
import WebImgPreviews from "../../common/WebImgPreviews";

//Img
import weather from "../../imgs/weather-dark.png";

export default function ReactWeatherProj() {
  return (
    <div className="px-2 mt-4" id="weather">
      <h4 className="text-center pt-2">React Weather App</h4>
      <div className="row pt-3">
        <div className="col-sm-6">
          <WebImgPreviews alt="React Weather App" source={weather} />
        </div>

        <div className="col-sm-6">
          <p className="py-2 image-descriptors">
            This project is the React version of the{" "}
            <a target="_self" href="#vanilla">
              Vanilla JS Weather App
            </a>{" "}
            below. <b>Search</b> for the weather in any city or{" "}
            <b>use the locater button</b>.
          </p>
          <p className="py-2 image-descriptors">
            This project is{" "}
            <b>
              optimised for <IconMapping icon="desktop" /> desktop,{" "}
              <IconMapping icon="tablet" /> tablets
            </b>
            {", "}
            and <IconMapping icon="mobile" /> some mobile devices. It also{" "}
            <b>hides/shows elements based on your device</b>.
          </p>
          <p className="image-descriptors">Main technologies:</p>
          <ul className="tech-stack-proj image-descriptors">
            <li>
              <IconMapping icon="react" />
            </li>
            <li>
              <IconMapping icon="css" />
            </li>
            <li>
              <IconMapping icon="api" />
            </li>
            <li>
              <IconMapping icon="netlify" />
            </li>
          </ul>
          <span className="btns-outer-margin image-descriptors">
            <TryBtn
              title="React Weather App"
              outlink="https://dreamy-chimera-4ed57d.netlify.app/"
            />
          </span>
        </div>
      </div>
      <BackToTop />
    </div>
  );
}
