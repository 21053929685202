import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "./styles/App.css";

//Sub pages
import CookieBanner from "./common/CookieBanner";
import CustomNavbar from "./common/CustomNavbar";
import Home from "./pages/Home";
import Privacy from "./pages/Privacy";
import ProjectsMain from "./pages/ProjectsMain";
import Footer from "./common/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <CustomNavbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="#contact" element={<Footer />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/projects" element={<ProjectsMain />} />
        </Routes>
        <Footer />
        <CookieBanner />
      </Router>
    </div>
  );
}

export default App;
