import React from "react";

export default function Privacy() {
  return (
    <div className="text-center px-2">
      <h1 className="mb-3">Privacy Policy </h1>
      <p>
        <strong>Last update: 29th January 2023</strong>
      </p>
      <p>This website and its developer take your privacy very seriously.</p>

      <p>
        This website logs no personal information on you and has no interest in
        doing so.
      </p>

      <p>
        This website does not collect, transmit, distribute or sell your
        personal data.
      </p>

      <p>
        This website uses no third party software however it uses analytics
        tools to provide a better user experience.
      </p>

      <p>
        For more information on Google's® Privacy Policy,{" "}
        <a
          target="_blank"
          rel="noreferrer nopener"
          href="https://policies.google.com/technologies/partner-sites"
          title="Google's privacy policy"
        >
          click here
        </a>
        .
      </p>

      <p>
        For more information on Microsoft's® Privacy Policy,{" "}
        <a
          target="_blank"
          rel="noreferrer nopener"
          href="https://privacy.microsoft.com/en-us/privacystatement"
          title="Microsoft's privacy policy"
        >
          click here
        </a>
        .
      </p>

      <p>© 2023 sylviaintech.com All Rights Reserved.</p>
    </div>
  );
}
