import React from "react";

//Common
import BackToTop from "../../common/BackToTop";
import IconMapping from "../../common/IconMapping";
import TryBtn from "../../common/TryBtn";
import WebImgPreviews from "../../common/WebImgPreviews";

//Img
import weatherLight from "../../imgs/weather-light.png";

export default function VanillaWeatherProj() {
  return (
    <div className="px-2 mt-4" id="vanilla">
      <h4 className="text-center pt-2">Vanilla Javascript Weather App</h4>
      <div className="row pt-3 reverse-order">
        <div className="col-sm-6 image-descriptors-reverse">
          <p>
            <b>Search</b> for the weather in any city or{" "}
            <b>use the locater button</b>. An error logic is also included for
            unknown locations.
          </p>
          <p className="py-2 image-descriptors-reverse">
            This project is{" "}
            <b>
              optimised for <IconMapping icon="desktop" /> desktop,{" "}
              <IconMapping icon="tablet" /> tablets
            </b>
            {", "}
            and <IconMapping icon="mobile" /> some mobile devices. It also{" "}
            <b>hides/shows elements based on your device</b>.
          </p>
          <p className="image-descriptors-reverse">
            There is also a{" "}
            <a target="_self" href="#weather">
              React version
            </a>{" "}
            of the weather app above.
          </p>
          <p className="image-descriptors-reverse">
            The main technologies used include:
          </p>
          <ul className="tech-stack-proj image-descriptors-reverse">
            <li>
              <IconMapping icon="js" /> (Vanilla)
            </li>
            <li>
              <IconMapping icon="css" />
            </li>
            <li>
              <IconMapping icon="api" />
            </li>
            <li>
              <IconMapping icon="github" />
            </li>
            <li>
              <IconMapping icon="netlify" />
            </li>
          </ul>
          <span className="btns-outer-margin image-descriptors-reverse">
            <TryBtn
              title="Vanilla Weather App"
              outlink="https://classy-horse-aa09ca.netlify.app/"
            />
          </span>
        </div>

        <div className="col-sm-6">
          <WebImgPreviews alt="the Vanilla Weather App" source={weatherLight} />
        </div>
      </div>
      <BackToTop />
    </div>
  );
}
