import React from "react";

//API & Flexbox have additional className as they dont' respond to font size changes

function apiIcon() {
  return (
    <span>
      <span className="material-symbols-outlined icon-stack-control">
        {" "}
        account_tree{" "}
      </span>{" "}
      API
    </span>
  );
}

function arrowFwdIcon() {
  return (
    <span>
      <i className="fa-solid fa-arrow-right"></i>
    </span>
  );
}

function bstrapIcon() {
  return (
    <span>
      <i className="fa-brands fa-bootstrap"></i> Bootstrap
    </span>
  );
}

function cssIcon() {
  return (
    <span>
      <i className="fa-brands fa-css3"></i> CSS3
    </span>
  );
}

function desktopIcon() {
  return (
    <span>
      <i className="fa-solid fa-desktop"></i>
    </span>
  );
}

function figmaIcon() {
  return (
    <span>
      <i className="fa-brands fa-figma"></i> Figma
    </span>
  );
}

function flexIcon() {
  return (
    <span>
      <span className="material-symbols-outlined icon-stack-control">
        {" "}
        dashboard{" "}
      </span>{" "}
      Flexbox
    </span>
  );
}

function gitIcon() {
  return (
    <span>
      <i className="fa-brands fa-git-alt"></i> Git
    </span>
  );
}

function githubIcon() {
  return (
    <span>
      <i className="fa-brands fa-github"></i> GitHub
    </span>
  );
}

function htmlIcon() {
  return (
    <span>
      <i className="fa-brands fa-html5"></i> HTML5
    </span>
  );
}

function jsIcon() {
  return (
    <span>
      <i className="fa-brands fa-js"></i> JavaScript
    </span>
  );
}

function languageIcon() {
  return (
    <span>
      <i className="fa-solid fa-language"></i>
    </span>
  );
}

function linkedinIcon() {
  return (
    <span>
      <i className="fa-brands fa-linkedin"></i> LinkedIn
    </span>
  );
}

function menuIcon() {
  return (
    <span>
      <i className="fa-solid fa-bars"></i>
    </span>
  );
}

function mobileIcon() {
  return (
    <span>
      <i className="fa-solid fa-mobile-screen-button"></i>
    </span>
  );
}

function netlifyIcon() {
  return (
    <span>
      <i className="fa-solid fa-globe"></i> Netlify
    </span>
  );
}

function nodeJSIcon() {
  return (
    <span>
      <i className="fa-brands fa-node-js"></i> NodeJS
    </span>
  );
}

function pinMapIcon() {
  return (
    <span title="You're here">
      <span className="material-symbols-outlined icon-pin-map">
        person_pin_circle
      </span>
    </span>
  );
}

function pythonIcon() {
  return (
    <span>
      <i className="fa-brands fa-python"></i> Python
    </span>
  );
}

function reactIcon() {
  return (
    <span>
      <i className="fa-brands fa-react"></i> React
    </span>
  );
}

function seoIcon() {
  return (
    <span>
      <i className="fa-solid fa-hashtag"></i> SEO optimization
    </span>
  );
}

function tabletIcon() {
  return (
    <span>
      <i className="fa-solid fa-tablet"></i>
    </span>
  );
}

function typescriptIcon() {
  return (
    <span>
      <i className="fa-solid fa-t"></i>
      <i class="fa-solid fa-s"></i> Typescript
    </span>
  );
}

function vsIcon() {
  return (
    <span>
      <i className="fa-solid fa-terminal"></i> VS Code
    </span>
  );
}

function zoomIcon() {
  return (
    <span>
      <i className="fa-solid fa-video"></i> Zoom
    </span>
  );
}

const iconMatching = {
  api: apiIcon(),
  arrowFwd: arrowFwdIcon(),
  bootstrap: bstrapIcon(),
  css: cssIcon(),
  desktop: desktopIcon(),
  figma: figmaIcon(),
  flexbox: flexIcon(),
  git: gitIcon(),
  github: githubIcon(),
  html: htmlIcon(),
  js: jsIcon(),
  language: languageIcon(),
  linkedin: linkedinIcon(),
  menu: menuIcon(),
  mobile: mobileIcon(),
  netlify: netlifyIcon(),
  nodeJS: nodeJSIcon(),
  pinMap: pinMapIcon(),
  python: pythonIcon(),
  react: reactIcon(),
  seo: seoIcon(),
  tablet: tabletIcon(),
  typescript: typescriptIcon(),
  vsCode: vsIcon(),
  zoom: zoomIcon(),
};

export default function IconMapping(props) {
  return <span>{iconMatching[props.icon]}</span>;
}
