import React from "react";

//Common
import BackToTop from "../../common/BackToTop";
import IconMapping from "../../common/IconMapping";
import TryBtn from "../../common/TryBtn";
import WebImgPreviews from "../../common/WebImgPreviews";

//Img
import dictionary from "../../imgs/dictionary.png";

export default function DictionaryProj() {
  return (
    <div className="px-2 mt-4" id="dictionary">
      <h4 className="text-center">React Dictionary App</h4>
      <div className="row pt-3 reverse-order">
        <div className="col-sm-6">
          <p className="image-descriptors-reverse">
            <b>Search</b> for any word in English and{" "}
            <b>view dictionary and image results</b> related to your word
            search.
          </p>
          <p className="image-descriptors-reverse">
            The page also <b>displays an error logic</b>. Try searching for a
            misspelt word (<i>eg, "freee" instead of "free"</i>) to test it out.
          </p>
          <p className="py-2 image-descriptors-reverse">
            This project is{" "}
            <b>
              optimised for all mobile, <IconMapping icon="tablet" /> tablet and{" "}
              <IconMapping icon="desktop" /> desktop devices
            </b>
            .
          </p>
          <p className="image-descriptors-reverse">
            The main technologies used include:
          </p>
          <ul className="tech-stack-proj image-descriptors-reverse">
            <li>
              <IconMapping icon="react" />
            </li>
            <li>
              <IconMapping icon="css" />
            </li>
            <li>
              <IconMapping icon="bootstrap" />
            </li>
            <li>
              <IconMapping icon="api" />
            </li>
            <li>
              <IconMapping icon="github" />
            </li>
            <li>
              <IconMapping icon="netlify" />
            </li>
          </ul>

          <span className="btns-outer-margin image-descriptors-reverse">
            <TryBtn
              outlink="https://bright-biscochitos-0fbcf3.netlify.app/"
              title="React Dictionary App"
            />
          </span>
        </div>

        <div className="col-sm-6">
          <WebImgPreviews source={dictionary} alt="the React Dictionary App" />
        </div>
      </div>
      <BackToTop />
    </div>
  );
}
