import React from "react";

import BackToTop from "../../common/BackToTop";
import IconMapping from "../../common/IconMapping";

export default function MyWebsite() {
  return (
    <div className="text-center mt-4" id="this">
      <h4 className="pt-2">This Website</h4>
      <div className="youre-here">
        <IconMapping icon="pinMap" />
      </div>
      <div className="row pt-3">
        <div className="col-sm-1" />
        <div className="col-sm-10">
          <p className="py-2">
            This website showcases my various projects and skills. Unlike my
            other projects below, this is my first{" "}
            <b>
              multi-page website built using React, and makes use of React
              Router DOM and Links
            </b>
            .
          </p>
          <p>
            It is closed source however I am happy to share the code with
            potential colleagues.
          </p>
          <p className="py-2">
            This website is{" "}
            <b>
              optimised for all <IconMapping icon="mobile" /> mobile and{" "}
              <IconMapping icon="desktop" /> desktop devices
            </b>
            .
          </p>
          <p className="this-site-tech">The main technologies used include:</p>
          <ul className="tech-stack-proj">
            <li>
              <IconMapping icon="react" />
            </li>
            <li>
              <IconMapping icon="css" />
            </li>
            <li>
              <IconMapping icon="bootstrap" />
            </li>
            <li>
              <IconMapping icon="github" />
            </li>
            <li>
              <IconMapping icon="netlify" />
            </li>
          </ul>
          <BackToTop />
        </div>
        <div className="col-sm-1" />
      </div>
    </div>
  );
}
