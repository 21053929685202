import React from "react";

export default function TryBtn(props) {
  let title = "Try out the ";

  return (
    <a
      target="_blank"
      href={props.outlink}
      rel="noreferrer nopener"
      title={title + props.title}
      className="try-app-btn"
    >
      Try it out!
    </a>
  );
}
