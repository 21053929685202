import React from "react";

import {Link} from "react-router-dom";

import BackToTop from "./BackToTop";
import IconMapping from "./IconMapping";

export default function Footer() {
  return (
    <div className="col-12 text-center">
      <footer>
        <hr />
        <div className="mx-1 my-3" id="contact">
          <h4 className="text-center contact-me">Contact Me</h4>
          <p>
            Connect with me on{" "}
            <a
              className="footlink"
              target="_blank"
              rel="noreferrer noopener"
              href="https://calendly.com/sylviaintech/"
              title="Book a call"
            >
              <IconMapping icon="zoom" />
            </a>{" "}
            or{" "}
            <a
              className="footlink"
              target="_blank"
              rel="noreferrer noopener"
              href="https://www.linkedin.com/in/sylvia78945/"
              title="Slide into my InMail"
            >
              <IconMapping icon="linkedin" />
            </a>
          </p>
        </div>
        <BackToTop />
        <hr />
        <div className="my-1">
          <p>
            This <b>multi-page website</b> is built using{" "}
            <IconMapping icon="react" />
            {", hosted on "}
            <a
              className="footlink"
              target="_blank"
              rel="noreferrer nopener"
              href="https://www.netlify.com"
              title="Open netlify.com"
            >
              <IconMapping icon="netlify" />{" "}
            </a>
            via <IconMapping icon="github" />, and coded by{" "}
            <a
              className="footlink"
              target="_blank"
              rel="noreferrer noopener"
              href="https://github.com/sylviaintech/"
              title="Checkout my Github"
            >
              SylviaInTech
            </a>
            {"."}
          </p>
          <div className="my-3">
            <Link className="footlink" title="Privacy Policy" to="/privacy">
              <strong>Privacy Policy</strong>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}
